import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthService } from '../../core/services/auth.service';
import { AppDisplayService } from '../../core/services/app-display.service';
import { InputError } from '../../shared/models/input-error';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../core/services/logger.service';
import { first, map, switchMap } from 'rxjs/operators';
import { PasswordService } from '../../core/services/password.service';
import { AppRoutingPasswordChanged } from '../../app-routing.model';
import { movinMotionApp } from '../../core/services/app-display.model';
import passwordEntropy from 'fast-password-entropy';
import { MixpanelService } from '@movinmotion/data-access-third-party';

@UntilDestroy()
@Component({
  selector: 'mm-page-new-password',
  templateUrl: './page-new-password.component.html',
  styleUrls: ['./page-new-password.component.scss'],
})
export class PageNewPasswordComponent implements OnInit {
  /**
   * Form fields names
   */
  formFieldKeys = {
    password: 'password',
  };

  /**
   * Errors control for the poassword input
   */
  passwordErrors: InputError[];
  asyncErrors: InputError[];
  /**
   * The password reset form
   */
  form = new FormGroup({
    [this.formFieldKeys.password]: new FormControl<string>('', {
      validators: [
        Validators.required,
        (control: FormControl | null): ValidationErrors => this.checkPasswordValidity(control),
      ],
    }),
  });

  submitLoading = false;

  private auth = inject(AuthService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslateService);
  private appDisplayService = inject(AppDisplayService);
  private logger: LoggerService = inject(LoggerService);
  private passwordService = inject(PasswordService);
  private mixpanel = inject(MixpanelService);

  private email: string;
  private otp: string;
  private PASSWORD_MINIMUM_SIZE = 8;
  private LETTER_PATTERN = /[a-zA-Z]+/;
  private NUMBER_PATTERN = /[0-9]+/;
  private redirectUrl: string;

  ngOnInit(): void {
    this.passwordErrors = ['tooShort', 'noLetter', 'noNumber'].map(errorKey => ({
      isError: control => control?.hasError(errorKey),
      text$: this.translate.stream(_(`mm.pages.new-password.password.errors.${errorKey}`)),
    }));
    this.asyncErrors = ['invalidOtp'].map(error => ({
      isError: control => control?.hasError(error),
      text$: this.translate.stream(_(`mm.pages.new-password.password.errors.${error}`)),
    }));
    this.appDisplayService
      .setTitle$(this.translate.stream(_('mm.pages.new-password.title.head')))
      .pipe(untilDestroyed(this))
      .subscribe();

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.has(environment.queryParamsKeys.email) && params.get(environment.queryParamsKeys.email)) {
        this.email = params.get(environment.queryParamsKeys.email);
        this.redirectUrl = params.get(environment.queryParamsKeys.redirectUrl);
        this.otp = params.get('otp');
        if (!this.otp || !this.email) {
          this.goBackToLoginPage();
        }
      }
    });
  }

  /**
   * The password reset submit of the form
   */
  submit(): void {
    const { [this.formFieldKeys.password]: password } = this.form.value;
    this.submitLoading = true;
    const entropy: number = passwordEntropy(password);
    this.mixpanel.track({ eventName: 'Modifier le mot de passe', sendImmediately: true });
    this.appDisplayService
      .getDisplay$()
      .pipe(
        first(),
        map(application => movinMotionApp[application]),
        switchMap(application => {
          return this.passwordService.changePassword$({
            password,
            entropy,
            otp: this.otp,
            application,
            email: this.email,
            offset: new Date()
              .toString()
              .match(/[+,-](\d{4})\s/g)[0]
              .trim(),
          });
        }),
        switchMap(() => this.route.queryParamMap),
      )
      .subscribe({
        next: params => {
          let queryParams = [];
          if (params.has(environment.queryParamsKeys.redirectUrl)) {
            queryParams = [params.get(environment.queryParamsKeys.redirectUrl)];
          }
          this.router.navigate([AppRoutingPasswordChanged], { queryParams, queryParamsHandling: 'merge' }).then();
        },
        error: error => {
          this.submitLoading = false;
          switch (error.name) {
            case 'HttpErrorResponse':
              if (error.status === 403) {
                this.form.get(this.formFieldKeys.password).setErrors({ invalidOtp: true });
              }
              break;
            default:
              this.logger.error(error);
          }
        },
      });
  }

  goBackToLoginPage(): void {
    this.mixpanel.track({ eventName: 'Annuler la modification de mot de passe', sendImmediately: true });
    this.auth.goToRedirectUrl$().pipe(untilDestroyed(this)).subscribe();
  }

  private checkPasswordValidity(passwordInput: FormControl): ValidationErrors {
    const password = passwordInput?.value;
    const errors: ValidationErrors = {};
    // 8 caractères minimum
    errors.tooShort = !password || password.length < this.PASSWORD_MINIMUM_SIZE;

    // au moins une lettre
    errors.noLetter = !password || !this.LETTER_PATTERN.exec(password);

    // au moins un chiffre
    errors.noNumber = !password || !this.NUMBER_PATTERN.exec(password);

    return Object.keys(errors)
      .filter(k => errors[k])
      .reduce((acc, k) => ({ ...acc, [k]: true }), {});
  }
}
