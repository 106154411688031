<mm-layout-card>
  <div class="password-changed-container">
    <h4 class="title mat-body-4">
      {{ 'mm.pages.password-changed.title.passwordModified' | translate }}
    </h4>

    <div class="icon-container">
      <mat-icon class="success-icon">check_circle</mat-icon>
    </div>

    <p class="subtitle mat-body">
      {{ 'mm.pages.password-changed.subtitle.1' | translate }}
    </p>

    <p class="subtitle subtitle-highlight mat-body">
      {{ 'mm.pages.password-changed.subtitle.2' | translate }}
    </p>

    <div class="buttons-container">
      <button
        mat-stroked-button
        type="button"
        (click)="navigateToProcessOrigin(true)"
        color="accent"
        class="mm-compact mm-borderless continue-button"
      >
        {{ 'mm.pages.password-changed.button.continue' | translate }}
      </button>
    </div>
  </div>
</mm-layout-card>
