import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { injectNgControl, NoopValueAccessorDirective } from '@movinmotion/util-ngx';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'mm-slide-toggle',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule],
  templateUrl: './slide-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective],
})
export class SlideToggleComponent {
  @Input() label?: string;

  ngControl = injectNgControl();
}
