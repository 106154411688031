import { Environment } from '../models/environment';

export const environment: Environment = {
  environment: 'test-2',
  production: true,
  api: {
    movinmotionBackend: 'https://back.app.test-2.movinmotion.net',
    movinmotionService: 'https://services.test-2.movinmotion.net',
  },
  appEndpoints: {
    talents: '/api/talent/v1',
    core: '/api/core/v1',
    filmfrance: 'api/filmfrance/v1',
    nda: '/api/nda/v1',
    movinmotion: '/api/movinmotion/v1',
  },
  authentication: {
    url: 'https://accounts.app.test-2.movinmotion.net',
    queryParamKeys: {
      token: 'mmt',
      entropy: 'mment',
      redirectUrl: 'mmr',
      resetPassword: 'mmrp',
      changePassword: 'mmcp',
      email: 'mme',
      movinmotionAuthError: 'mmae',
      language: 'mml',
    },
  },
  apps: {
    core: 'https://entreprise.app.test-2.movinmotion.net',
    worker: 'https://salarie.app.test-2.movinmotion.net',
    compta: 'https://compta.app.test-2.movinmotion.net',
    talents: 'https://talents.app.test-2.movinmotion.net',
    insurance: 'https://assurance.app.test-2.movinmotion.net',
    socialPlay: 'https://app.test-2.movinmotion.net',
    social: 'https://social.app.test-2.movinmotion.net',
    admin: 'https://admin.app.test-2.movinmotion.net',
    movinmotion: 'https://www.movinmotion.com',
  },
  thirdParty: {
    siren: {
      endpoint: 'https://api.insee.fr/api-sirene/3.11',
      token: '30bb2afa-df6f-4447-bb2a-fadf6f144710',
    },
    googlePlaces: 'AIzaSyCIvi5dIQkPbAgJcYjShYmSjpiTWYEABPg',
    yousign: {
      sandbox: true,
    },
    mixpanel: {
      token: '668a9eba9515c4e5bacd50ebbb8adee5',
      name: 'MM test',
    },
    axeptio: {
      clientId: '6501875f451c3565dc89dda9',
      cookiesVersion: 'movinmotion - application - test-fr',
    },
  },
  bucket: {
    avatars: 'avatars.storage.test-2.movinmotion.net',
  },
  cookieDomain: '.app.test-2.movinmotion.net',
};
